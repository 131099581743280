<template>
  <div>
    <!-- Start Footer Area  -->
    <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-6 col-12">
              <div class="rn-footer-widget text-center">
                <h4 class="title">产品介绍</h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li>
                      <router-link to="/product-introduction/1">手持式光传输线路智能运维仪(光缆路由定位仪)</router-link>
                    </li>
                    <li>
                      <router-link to="/product-introduction/2">同步八通道振动智能监测设备</router-link>
                    </li>
                    <li>
                      <router-link to="/product-introduction/3">小型低功耗光纤振动智能监测设备</router-link>
                    </li>
                    <li>
                      <router-link to="/product-introduction/4">小型低功耗光纤温度智能监测设备</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-12">
              <div class="rn-footer-widget text-center">
                <h4 class="title">行业应用</h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li>
                      <router-link to="/industry-application/1">通讯领域智能监测</router-link>
                    </li>
                    <li>
                      <router-link to="/industry-application/2">石化领域智能监测</router-link>
                    </li>
                    <li>
                      <router-link to="/industry-application/3">电力领域智能监测</router-link>
                    </li>
                    <li>
                      <router-link to="/industry-application/4">交通领域智能监测</router-link>
                    </li>
                    <li>
                      <router-link to="/industry-application/5">国防领域智能监测</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-12">
              <div class="rn-footer-widget text-center">
                <h4 class="title">
                  <router-link to="/success-cases/1">成功案例</router-link>
                </h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li>
                      <router-link to="/success-cases/1">通讯领域案例展示</router-link>
                    </li>
                    <li>
                      <router-link to="/success-cases/1">石化领域案例展示</router-link>
                    </li>
                    <li>
                      <router-link to="/success-cases/1">电力领域案例展示</router-link>
                    </li>
                    <li>
                      <router-link to="/success-cases/1">交通领域案例展示</router-link>
                    </li>
                    <li>
                      <router-link to="/success-cases/1">国防领域案例展示</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-12">
              <div class="rn-footer-widget text-center">
                <div class="widget-menu-top">
                  <h4 class="title">关于远知</h4>
                  <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <router-link to="/about-us">公司简介</router-link>
                      </li>
                      <li>
                        <router-link to="/about-us">团队介绍</router-link>
                      </li>
                      <li>
                        <router-link to="/about-us">生产规模</router-link>
                      </li>
                      <li>
                        <router-link to="/about-us">研发领头人</router-link>
                      </li>
                      <li>
                        <router-link to="/about-hide">隐私政策</router-link>
                      </li>
                      <li v-show="false">
                        <router-link to="/app-agreement">运维仪隐私政策</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-6 col-12">
              <div class="rn-footer-widget text-center">
                <h4 class="title ">联系我们</h4>
                <img :src="require('@/assets/images/fs/webCode.png')" alt="官微" title="官微" width="100">
                <div class="inner">
                  <div class="call-text">
                    <div>无锡远知科技发展有限公司</div>
                    <div>地址：<span style="font-size: 12px">江苏省无锡市新吴区新华路5号创新创意产业园C栋5层</span></div>
                    <div>电话：0510-88999969</div>
                  </div>
                  <ul class="call-icon social-icon social-default justify-content-center">
                    <li>
                      <a href="javascript:void(0)">
                        <div class="wechat">
                          <img :src="require('@/assets/images/fs/social-wechat.svg')" alt="wechat" title="wechat">
                          <img class="wechat-qr"
                               :src="require('@/assets/images/fs/social-wechat-qr.jpg')"
                               alt="wechat" title="微信扫描添加">
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <div class="feishu">
                          <img :src="require('@/assets/images/fs/social-feishu.svg')" alt="feishu" title="飞书">
                          <img class="feishu-qr"
                               :src="require('@/assets/images/fs/social-feishu-qr.jpg')"
                               alt="feishu" title="飞书扫描添加">
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="https://s.weibo.com/weibo?q=%E5%85%89%E7%BA%A4%E4%BC%A0%E6%84%9F" target="_blank">
                        <img :src="require('@/assets/images/fs/weibo.svg')" alt="weibo" title="weibo">
                      </a>
                    </li>
                    <li>
                      <a href="https://space.bilibili.com/51583442" target="_blank">
                        <img :src="require('@/assets/images/fs/bilibili.svg')" alt="bilibili" title="bilibili">
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- End Footer Area  -->

    <Copyright v-if="data.showCopyright"/>
    <ScrollTop/>
  </div>
</template>

<script>

import Copyright from "@/components/common/footer/FSCopyright";
import Icon from "@/components/icon/Icon";
import ScrollTop from "@/components/common/footer/ScrollTop";
import Logo from "@/components/elements/logo/Logo";

export default {
  name: 'FSFooter',
  components: {Logo, ScrollTop, Icon, Copyright},
  methods: {},
  props: {
    data: {
      default: null
    }
  }
}
</script>
<style scoped lang="scss">
body.active-light-mode .call-icon li a {
  background-color: #00ADEF !important;
}

body.active-light-mode .call-icon li a image {
  background-color: black !important;
}

.inner {
  .call-text {
    font-size: 13px;
  }

  .call-icon {
    margin-top: 15px;
  }

  .wechat, .feishu {
    position: relative;

    .wechat-qr, .feishu-qr {
      position: absolute;
      display: none;
    }
  }

  a:hover .wechat, a:hover .feishu {
    .wechat-qr, .feishu-qr {
      display: block;
      text-align: left;
      left: 0;
      bottom: 100px;
      transform: scale(3);
    }
  }
}
</style>
