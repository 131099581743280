<template>
  <div class="pi2">

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品简述</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row" style="align-items: center">
              <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <div class="mt-5">
                  <img :src="require(`@/assets/images/fs/product-0-2.jpg`)" alt="">
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 offset-lg-1 offset-md-1">
                <div class="mt-5">
                 <p>
                   在分布式光纤测温系统（DTS）中，多采用光开关方案解决一台设备监测多路光纤的问题：只需将单通道系统加装多通道光开关，采用分时轮巡方式在某时刻只采集多根光纤其中一根的信号，软件也同时只计算当前通道数据，再切换采集下一通道……。实际环境中温度变化较慢，光开关方案是性价比较高；但对于DVS，振动信号瞬息万变，需要对各通道实时采集分析才能确保不错过重要的振动数据，达到不漏报的安全预警目标。因此，会增加响应时间、降低报警准确率、带来漏报可能的光开关方案，就不是DVS多通道测量的最优解。
                 </p>
                  <p>
                    公司研发的同步八通道分布式光纤振动监控系统，采用了八个独立的振动传感模块，仅需一台4U主机即可做到8个通道同步实时探测、分析，每通道都无探测盲区时间，实际效果等同于8台单通道主机，但对每路光缆监控的综合成本、功耗及机柜空间占用都明显降低。
                  </p>
                  <p>
                    同步多通道分布式光纤振动传感系统，基于相位敏感光时域反射（Φ-OTDR）原理，将主机任一通道接口接入长距离光缆一芯光纤的单端，即可实现全线振动信号实时采集、振动事件的米级定位，是对“星形放射状”多线路进行实时安全、外破预警监测的最佳解决方案。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">监测原理</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-title" data-aos="fade-up">
                  <h4 class="subtitle">系统工作原理</h4>
                </div>
              </div>
              <div class="col-5">
                传感光纤没有受到外界干扰情况下，激光信号正常传播路径，如下图：
              </div>
              <div class="col-5 offset-2">
                传感光纤感应到振动、移动等干扰时，激光信号发生偏移，如下图：
              </div>
              <div class="col-12 mb-5">
                <img :src="require('@/assets/images/fs/pi-2-1.png')" alt="">
              </div>
            </div>
            <div class="col-lg-12">
              <div class="section-title" data-aos="fade-up">
                <h4 class="subtitle">定位原理</h4>
              </div>
            </div>
            <div class="col-12">
              系统发出光脉冲在光纤中运行时，经过不同位置激发的散射光，再回到系统的时间差，通过精确计算，就能得到各振动点在光纤上的位置信息，这也就是基于光时域反射技术（OTDR），从而得到振动信号源的位置。本系统的高速数据采集模块采用了100MHz的采集速率，即对光缆线路沿线进行每米的密集采样探测，再结合高精度的传感算法，实际现场定位精度达10米内。
              <br/>
              系统在初次运行时，系统会计算当时线路的长度，如果在后期运行中因为外界破坏使得线路发生损坏断裂，系统探测到的线路长度会明显变短，此时就触发了线路断裂警报，最新线路长度末端问题，即为断纤事故发生的位置。
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">技术优势</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <div class="mt-5">
                  <p>
                    在分布式光纤测温系统（DTS）中，多采用光开关方案解决一台设备监测多路光纤的问题：只需将单通道系统加装多通道光开关，采用分时轮巡方式在某时刻只采集多根光纤其中一根的信号，软件也同时只计算当前通道数据，再切换采集下一通道……。实际环境中温度变化较慢，光开关方案是性价比较高；但对于DVS，振动信号瞬息万变，需要对各通道实时采集分析才能确保不错过重要的振动数据，达到不漏报的安全预警目标。因此，会增加响应时间、降低报警准确率、带来漏报可能的光开关方案，就不是DVS多通道测量的最优解。
                  </p>
                  <p>
                    我们的同步四通道分布式光纤振动监控系统，采用了四个独立的振动传感模块，仅需一台主机即可做到8个通道同步实时探测、分析，每通道都无探测盲区时间，实际效果等同于8台单通道主机，但对每路光缆监控的综合成本、功耗及机柜空间占用都明显降低。
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 offset-lg-1 offset-md-1">
                <div class="mt-5">
                  <img :src="require(`@/assets/images/fs/product2.png`)" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">技术参数</h2>
            </div>
          </div>
          <div class="col-lg-12 mt-5">
            <img :src="require(`@/assets/images/fs/pi-2-3.jpg`)" alt="">
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品应用</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row row--15 service-wrapper">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12" v-for="application in applications">
                <div data-aos="fade-up" data-aos-delay="70" class="aos-init aos-animate">
                  <div class="service service__style--2 text-center">
                    <div class="inner">
                      <div class="image"><img :src="application.image" alt="Service Images"/></div>
                      <div class="content">
                        <h4 class="title"><a href="javascript:void(0)" class="">{{ application.title }}</a></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

  </div>
</template>

<script>
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "PI2",
  components: {Separator},
  data () {
    return {
      applications: [
        {title: '管道防外破监测', image: require('@/assets/images/fs/pi-2-4-1.jpg')},
        {title: '公路护栏撞击监测', image: require('@/assets/images/fs/pi-2-4-2.jpg')},
        {title: '通讯光缆防外破监测', image: require('@/assets/images/fs/pi-2-4-3.jpg')},
        {title: '电缆防外破监测', image: require('@/assets/images/fs/pi-2-4-4.jpg')},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.image{
  img{
    max-height: inherit!important;
  }
}
.rn-section-gap{
  padding: 60px 0 !important;
}
.contentPad{
  padding-top: 0 !important;
}
</style>
